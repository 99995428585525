import React from "react";
import { Flex, Container } from "theme-ui";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { Wallet } from "@ethersproject/wallet";

import { Decimal, Difference, Trove } from "@liquity/lib-base";
import { LiquityStoreProvider } from "@liquity/lib-react";

import { useLiquity } from "./hooks/LiquityContext";
import { TransactionMonitor } from "./components/Transaction";
import { UserAccount } from "./components/UserAccount";
import { SystemStatsPopup } from "./components/SystemStatsPopup";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";

import { PageSwitcher } from "./pages/PageSwitcher";
import { RiskyTrovesPage } from "./pages/RiskyTrovesPage";
import { RedemptionPage } from "./pages/RedemptionPage";
// import { Bonds } from "./pages/Bonds";

import { TroveViewProvider } from "./components/Trove/context/TroveViewProvider";
import { StabilityViewProvider } from "./components/Stability/context/StabilityViewProvider";
import { StakingViewProvider } from "./components/Staking/context/StakingViewProvider";
import "tippy.js/dist/tippy.css"; // Tooltip default style
import { BondsProvider } from "./components/Bonds/context/BondsProvider";

import { Farm } from "./pages/Farm";
import { FarmViewProvider } from "./components/Farm/context/FarmViewProvider";
import { FarmViewProvider2 } from "./components/Farmlp/context/FarmViewProvider";
import { DistributionPage } from "./pages/DistributionPage";

type LiquityFrontendProps = {
  loader?: React.ReactNode;
};
export const LiquityFrontend: React.FC<LiquityFrontendProps> = ({ loader }) => {
  const { account, provider, liquity } = useLiquity();

  // For console tinkering ;-)
  Object.assign(window, {
    account,
    provider,
    liquity,
    Trove,
    Decimal,
    Difference,
    Wallet,
  });

  return (
    <LiquityStoreProvider {...{ loader }} store={liquity.store}>
      <Router>
        <TroveViewProvider>
          <StabilityViewProvider>
            <StakingViewProvider>
              <BondsProvider>
                <FarmViewProvider>
                <FarmViewProvider2>
                  <Flex sx={{ flexDirection: "column", minHeight: "100%" }}>
                    <Header>
                      <UserAccount />
                      <SystemStatsPopup />
                    </Header>

                    <Container
                      variant="main"
                      sx={{
                        display: "flex",
                        flexGrow: 1,
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Switch>
                        <Route path="/" exact>
                          <PageSwitcher />
                        </Route>
                        {/* <Route path="/bonds">
                        <Bonds />
                      </Route> */}
                        <Route path="/lp-staking">
                          <Farm />
                        </Route>
                        {/* <Route path="/redemption">
                          <RedemptionPage />
                        </Route> */}
                        <Route path="/risky-vaults">
                          <RiskyTrovesPage />
                        </Route>
                        <Route path="/distribution">
                          <DistributionPage />
                        </Route>
                      </Switch>
                    </Container>

                    <Footer />
                  </Flex>
                </FarmViewProvider2>
                </FarmViewProvider>
              </BondsProvider>
            </StakingViewProvider>
          </StabilityViewProvider>
        </TroveViewProvider>
      </Router>
      <TransactionMonitor />
    </LiquityStoreProvider>
  );
};
